import { useEffect, useState, FormEvent } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../../api';
import { trimEditedInput, getErrorMessage } from '../../../helper';
import { AssignmentGroup } from '../../../models/AssignmentGroup';

type AssignmentGroupEditFormProps = {
  assignmentGroup: AssignmentGroup;
  editMode: boolean;
  onClose(): void;
};

function AssignmentGroupEditForm(props: AssignmentGroupEditFormProps) {
  const { assignmentGroup, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(assignmentGroup.name);
  }, [assignmentGroup]);

  const mutation = useMutation(
    (input: AssignmentGroup) => {
      // Validate and clean up input fields
      const name = trimEditedInput(input.name);
      setName(name);

      return api.updateAssignmentGroup(input.id, name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['assignmentGroup', assignmentGroup.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({
      ...assignmentGroup,
      name,
    });
  };

  const onCancel = () => {
    setName(assignmentGroup.name);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Assignment Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Assignment Group</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Update
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function AssignmentGroupDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();
  const assignmentGroupId = parseInt(params.assignmentGroupId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: assignmentGroup,
    error,
    isLoading,
    isSuccess,
  } = useQuery(['assignmentGroup', assignmentGroupId], () =>
    api.getAssignmentGroup(assignmentGroupId)
  );

  const deleteMutation = useMutation<void, Error, number>((id) => api.deleteAssignmentGroup(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['assignmentGroups']);
      navigate('/assignmentGroups');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <div>
          <h1>Name: {assignmentGroup.name}</h1>
          <Button variant="primary" onClick={() => setEditMode(true)}>
            EDIT
          </Button>
          <Button variant="danger" onClick={() => deleteMutation.mutate(assignmentGroup.id)}>
            DELETE
          </Button>
        </div>
        <AssignmentGroupEditForm
          assignmentGroup={assignmentGroup}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }
  if (deleteMutation.isError) {
    return <div>Failed to delete assignment group: {deleteMutation.error.message}</div>;
  }
  return <div>Error occurred</div>;
}

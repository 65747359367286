import { FormEvent, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../../api';
import AssignmentGroupDropdown from '../../../components/AssignmentGroupDropdown/AssignmentGroupDropdown';
import { getErrorMessage, trimEditedInput } from '../../../helper';
import { Platform } from '../../../models/Platform';
import { AssignmentGroup } from '../../../models/AssignmentGroup';

type PlatformEditFormProps = {
  platform: Platform;
  editMode: boolean;
  onClose(): void;
};

type PlatformEditFormData = {
  id: number;
  name: string;
  assignmentGroup: AssignmentGroup;
};

function PlatformEditForm(props: PlatformEditFormProps) {
  const { platform, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');
  const [assignmentGroup, setAssignmentGroup] = useState<AssignmentGroup | undefined>(undefined);

  useEffect(() => {
    setName(platform.name);
    api
      .getAssignmentGroup(platform.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
  }, [api, platform]);

  const mutation = useMutation(
    (input: PlatformEditFormData) => {
      const trimmedName = trimEditedInput(input.name);
      setName(trimmedName);
      setAssignmentGroup(input.assignmentGroup);

      return api.updatePlatform(input.id, trimmedName, input.assignmentGroup.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['platform', platform.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!assignmentGroup) {
      throw Error('No assignment group selected');
    }
    mutation.mutate({
      ...platform,
      name,
      assignmentGroup,
    });
  };

  const onCancel = () => {
    setName(platform.name);
    api
      .getAssignmentGroup(platform.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Platform</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Assignment Group</Form.Label>
            <AssignmentGroupDropdown value={assignmentGroup} onChange={setAssignmentGroup} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Update
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function PlatformDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();
  const platformId = parseInt(params.platformId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: platformDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['platform', platformId], async () => {
    const platform = await api.getPlatform(platformId);
    const assignmentGroup = await api.getAssignmentGroup(platform.assignmentGroupId);
    return { platform, assignmentGroup };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deletePlatform(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['platforms']);
      navigate('/platforms');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <h1>Name: {platformDetailPage.platform.name} </h1>
        <h1>
          Assignment Group: {platformDetailPage.assignmentGroup.name ?? 'No Assignment Group'}{' '}
        </h1>
        <Button variant="primary" onClick={() => setEditMode(true)}>
          EDIT
        </Button>
        <Button
          variant="danger"
          onClick={() => deleteMutation.mutate(platformDetailPage.platform.id)}
        >
          DELETE
        </Button>
        <PlatformEditForm
          platform={platformDetailPage.platform}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  if (deleteMutation.isError) {
    return <div>Failed to delete platform: {deleteMutation.error.message}</div>;
  }

  return <div>Error occurred</div>;
}

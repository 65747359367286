import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
// import { useState, useEffect } from 'react';

import { config } from './config';
import { AssignmentGroup } from './models/AssignmentGroup';
import { Channel } from './models/Channel';
import { Constants } from './models/Constants';
import { PaginationInfo } from './models/PaginationInfo';
import { Platform } from './models/Platform';

type ApiResponse<T> = {
  status: string;
  message?: string;
  data: T;
};

type PlatformPage = {
  platforms: Platform[];
  pagination: PaginationInfo;
};

type ChannelPage = {
  channels: Channel[];
  pagination: PaginationInfo;
};

type AssignmentGroupPage = {
  assignmentGroups: AssignmentGroup[];
  pagination: PaginationInfo;
};

class ApiClient {
  private readonly msalInstance?: IPublicClientApplication;
  private readonly axios: AxiosInstance;

  constructor(msalInstance?: IPublicClientApplication) {
    this.msalInstance = msalInstance;
    this.axios = axios.create({
      baseURL: config.api.baseUrl,
    });
  }

  private async getToken(): Promise<string> {
    if (!this.msalInstance) {
      throw new Error('No Microsoft authentication is not configured!');
    }

    // NOTE: MSAL caches tokens internally
    const account = this.msalInstance.getActiveAccount();
    if (account) {
      const request = {
        scopes: config.azureAd.scopes,
        account,
      };
      try {
        const response = await this.msalInstance.acquireTokenSilent(request);
        return response.idToken;
      } catch (err) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (err instanceof InteractionRequiredAuthError) {
          const response = await this.msalInstance.acquireTokenPopup(request);
          return response.idToken;
        } else {
          throw err;
        }
      }
    }
    throw new Error('No active Azure AD account');
  }

  private async makeRequest<T>(method: Method, endpoint: string, config?: AxiosRequestConfig) {
    const token = await this.getToken();
    const requestConfig: AxiosRequestConfig = {
      method,
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (config) {
      requestConfig.params = config.params;
      requestConfig.data = config.data;
    }

    try {
      const res = await this.axios.request<ApiResponse<T>>(requestConfig);
      return res.data.data;
    } catch (err) {
      if (err.response) {
        const message = err.response.data.message || 'Internal error';
        const apiError = new Error(`[API][${method} ${endpoint}]${message}`);
        Object.assign(apiError, { httpStatus: err.response.status });
        throw apiError;
      } else if (err.request) {
        throw new Error(`[API][${method} ${endpoint}][TIMEOUT] ${err.message}`);
      } else {
        throw new Error(`[API][${method} ${endpoint}][FAILED_REQUEST] ${err.message}`);
      }
    }
  }

  async createPlatform(name: string, assignmentGroupId: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('POST', '/api/platforms', {
      data: {
        name,
        assignmentGroupId,
      },
    });
    return response.platform;
  }

  async getPlatforms(cursor?: number): Promise<PlatformPage> {
    return this.makeRequest<PlatformPage>('GET', `/api/platforms`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async getPlatform(id: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('GET', `/api/platforms/${id}`);
    return response.platform;
  }

  async updatePlatform(id: number, name: string, assignmentGroupId: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('PUT', `/api/platforms/${id}`, {
      data: {
        name,
        assignmentGroupId,
      },
    });
    return response.platform;
  }

  async deletePlatform(id: number) {
    await this.makeRequest<{ platform: Platform }>('DELETE', `/api/platforms/${id}`);
  }

  async getAssignmentGroups(cursor?: number): Promise<AssignmentGroupPage> {
    return this.makeRequest<AssignmentGroupPage>('GET', `/api/assignmentGroups`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async createAssignmentGroup(name: string): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'POST',
      '/api/assignmentGroups',
      {
        data: {
          name,
        },
      }
    );
    return response.assignmentGroup;
  }

  async getAssignmentGroup(id: number): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'GET',
      `/api/assignmentGroups/${id}`
    );
    return response.assignmentGroup;
  }

  async updateAssignmentGroup(id: number, name: string): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'PUT',
      `/api/assignmentGroups/${id}`,
      {
        data: {
          name,
        },
      }
    );
    return response.assignmentGroup;
  }

  async deleteAssignmentGroup(id: number) {
    await this.makeRequest<{ channel: AssignmentGroup }>('DELETE', `/api/assignmentGroups/${id}`);
  }

  async getChannels(cursor?: number): Promise<ChannelPage> {
    return this.makeRequest<ChannelPage>('GET', `/api/channels`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async createChannel(
    channelId: string,
    name: string,
    assignmentGroupId: number
  ): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('POST', '/api/channels', {
      data: {
        channelId,
        name,
        assignmentGroupId,
      },
    });
    return response.channel;
  }

  async getChannel(id: number): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('GET', `/api/channels/${id}`);
    return response.channel;
  }

  async updateChannel(
    id: number,
    channelId: string,
    name: string,
    assignmentGroupId: number
  ): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('PUT', `/api/channels/${id}`, {
      data: {
        channelId,
        name,
        assignmentGroupId,
      },
    });
    return response.channel;
  }

  async deleteChannel(id: number) {
    await this.makeRequest<{ channel: Channel }>('DELETE', `/api/channels/${id}`);
  }
}

let apiClient: ApiClient | undefined;

export default function useApi(): ApiClient {
  // TODO There has to be a better way
  // const [api, setApi] = useState<ApiClient>(new ApiClient());
  // const { instance } = useMsal();

  // useEffect(() => {
  //   setApi(new ApiClient(instance));
  // }, [instance]);

  // return api;

  const { instance } = useMsal();
  if (!apiClient) {
    apiClient = new ApiClient(instance);
  }
  return apiClient;
}

import { Route, Routes } from 'react-router-dom';
import AssignmentGroupDetailPage from '../../pages/AssignmentGroups/_id';
import AssignmentGroups from '../../pages/AssignmentGroups';
import ChannelDetailPage from '../../pages/Channels/_id';
import Channels from '../../pages/Channels';
import HomePage from '../../pages/Homepage';
import PlatformDetailPage from '../../pages/Platforms/_id';
import Platforms from '../../pages/Platforms';

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/platforms" element={<Platforms />} />
      <Route path="/platforms/:platformId" element={<PlatformDetailPage />} />
      <Route path="/channels" element={<Channels />} />
      <Route path="/channels/:channelNumberId" element={<ChannelDetailPage />} />
      <Route path="/assignmentGroups" element={<AssignmentGroups />} />
      <Route path="/assignmentGroups/:assignmentGroupId" element={<AssignmentGroupDetailPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}

import { FormEvent, useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../../api';
import AssignmentGroupDropdown from '../../../components/AssignmentGroupDropdown/AssignmentGroupDropdown';
import { getErrorMessage, trimEditedInput } from '../../../helper';
import { AssignmentGroup } from '../../../models/AssignmentGroup';
import { Channel } from '../../../models/Channel';

type ChannelEditFormProps = {
  channel: Channel;
  editMode: boolean;
  onClose(): void;
};

type ChannelEditFormData = {
  id: number;
  name: string;
  channelId: string;
  assignmentGroup: AssignmentGroup;
};

function ChannelEditForm(props: ChannelEditFormProps) {
  const { channel, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');
  const [channelId, setChannelId] = useState<string>('');
  const [assignmentGroup, setAssignmentGroup] = useState<AssignmentGroup | undefined>(undefined);

  useEffect(() => {
    setName(channel.name);
    setChannelId(channel.channelId);
    api
      .getAssignmentGroup(channel.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
  }, [api, channel]);

  const mutation = useMutation(
    (input: ChannelEditFormData) => {
      const trimmedName = trimEditedInput(input.name);
      const trimmedChannelId = trimEditedInput(input.channelId);
      setName(trimmedName);
      setChannelId(trimmedChannelId);
      setAssignmentGroup(input.assignmentGroup);

      return api.updateChannel(input.id, trimmedChannelId, trimmedName, input.assignmentGroup.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channel', channel.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!assignmentGroup) {
      throw Error('No assignment group selected');
    }
    mutation.mutate({
      ...channel,
      channelId,
      name,
      assignmentGroup,
    });
  };

  const onCancel = () => {
    setName(channel.name);
    setChannelId(channel.channelId);
    api
      .getAssignmentGroup(channel.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Channel ID</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={channelId}
              onChange={(e) => setChannelId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Channel Name</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Assignment Group</Form.Label>
            <AssignmentGroupDropdown value={assignmentGroup} onChange={setAssignmentGroup} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Update
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function ChannelDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();

  const channelNumberId = parseInt(params.channelNumberId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: channelDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['channel', channelNumberId], async () => {
    const channel = await api.getChannel(channelNumberId);
    const assignmentGroup = await api.getAssignmentGroup(channel.assignmentGroupId);
    return { channel, assignmentGroup };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deleteChannel(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['channels']);
      navigate('/channels');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <h1>Channel ID: {channelDetailPage.channel.channelId} </h1>
        <h1>Name: {channelDetailPage.channel.name} </h1>
        <h1>Assignment Group: {channelDetailPage.assignmentGroup.name ?? 'No assignment group'}</h1>
        <Button
          variant="primary"
          onClick={() => {
            setEditMode(true);
          }}
        >
          EDIT
        </Button>
        <Button
          variant="danger"
          onClick={() => deleteMutation.mutate(channelDetailPage.channel.id)}
        >
          DELETE
        </Button>
        <ChannelEditForm
          channel={channelDetailPage.channel}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  if (deleteMutation.isError) {
    return <div>Failed to delete platform: {deleteMutation.error.message}</div>;
  }

  return <div>Error occurred</div>;
}
